@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.MuiDialog-container {
    font-family: "Inter", BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
    line-height: 1.2 !important;
}

.mintModal-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    overflow: visible;
    min-width: 280px;
}

@media screen and (min-width: 600px) {
    .mintModal-content {
        min-width: 300px;
    }
}

.web3modal-modal-lightbox {
    z-index: 10000 !important;
}

.attribution-link {
    opacity: 0.75;
    font-size: 0.75rem;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    width: 100%;
    position: static;
    padding: 4px 0;
    display: block;
    bottom: -28px;
    left: 0;
    right: 0;
}